<template>
  <v-dialog v-model="parcelDetail.dialog" max-width="600" eager>
    <ParcelDetailById
      hide-thumbnail-image
      export-parcel
      :no-map-redirect="viewMode === 'map'"
      :id="parcelDetail.id"
      @close="setParcelDetail('dialog', false)"
      :showClose="true"
    >
    </ParcelDetailById>
  </v-dialog>
</template>

<script>
export default {
  components: {
    ParcelDetailById: () => import('./DetailById.vue')
  },
  computed: {
    viewMode() {
      return this.$route.path.split('/').pop();
    },
    parcelDetail() {
      return this.$store.state.parcel.detail;
    }
  },
  methods: {
    setParcelDetail(key, value) {
      this.$store.commit('setParcelDetail', { key, value });
    }
  }
};
</script>

<style>
</style>
